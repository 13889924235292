// .storybook/manager.js
import { addons } from "@storybook/addons";
import { create } from "@storybook/theming/create";
import logo from "./logo.png";

const customTheme = create({
  base: "dark",
  brandTitle: "Elliot's component library",
  brandUrl: "https://elliotlafave.com",
  brandImage: logo,

  // Color palette
  colorPrimary: "#7149E9",
  colorSecondary: "#6c757d",

  // UI
  appBg: "#0a0114",
  appContentBg: "#0a0114",
  appBorderColor: "#585C6D",
  appBorderRadius: 0,

  // Text colors
  textColor: "#ffffff",
  textInverseColor: "#ffffff",

  // Toolbar default and active colors
  barTextColor: "#9E9E9E",
  barSelectedColor: "#585C6D",
  barBg: "#0a0114",
});

addons.setConfig({
  theme: customTheme,
});
